<template>
  <div class="playground-generals-inf-container container-fluid">
    <b-card class="playground-generals-inf-card">
      <div class="row-style">
        <d-body-nav-bar
            :tabs="[]"
            position="float-right"
            :title="'views.playground.steps.step-2.time-zone-list.title'"
            :title-class="'topnav-title-red-light'"
        />
      </div>
      <d-table
          identifier="block"
          :tablefields="tableFields"
          :items="items"
          :per-page="itemsPerPage"
          :current-page="currentPage"
          :totalRows="totalRows"
          :is-busy="isBusy"
          :show-pagination="items.length"
          :empty-text="selectedTimetable ? 'components.doinsport.table.empty-text' : 'components.custom.playground.new.step-2.calendar.empty-timetables-blocks'"
          @on:data-table-btn:edit="onRowEdit"
          @on:block:delete="onRowDelete"
          @on:pagination-model:update="onPaginationUpdate"
      >
      </d-table>
      <b-row v-if="selectedTimetable" no-gutters class="mt-3" align="center">
        <b-col>
          <d-button
              :text="'views.playground.steps.step-2.time-zone-list.add-new-timezone'"
              class="'d-btn-sm btn d-btn-primary-outline font-text-title'"
              icon="fa fa-plus mr-1"
              :class="isDisabled === true ? 'disabled' : ''"
              @on:button-click="onNewTimeTableBlock"
          />
        </b-col>
      </b-row>
    </b-card>
    <new-timezone-modal
        :key="'new-timezone-modal' + componentModalKey"
        :timetable="selectedTimetable"
        :block="block"
        :model="model"
        :display="display"
    />
  </div>
</template>
<script>
import { deleteBlock } from "@api/doinsport/services/timetable/blocks/block.api";
import Block from "@/classes/doinsport/Block";
import {SUCCESS} from "@plugins/flash";

export default {
  components: {
    NewTimezoneModal: () => import('@custom/playground/new/step-2/NewTimezoneModal')
  },
  data: () => ({
    isDisabled: true,
    items: [],
    display: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalRows: 1,
    isBusy: false,
    model: null,
    block: new Block(),
    timetableId: null,
    selectedTimetable: null,
    componentModalKey: 0,
  }),
  computed: {
    alignCellsToLeft () {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    tableFields () {
      return [
        { key: "block", label: this.$t("views.playground.steps.step-2.time-zone-list.table.block"), sortable: false },
        { key: "name", label: this.$t("views.playground.steps.step-2.time-zone-list.table.name"), sortable: false },
        { key: "userClientStepBookingDuration", class: this.alignCellsToLeft, label: this.$t("views.playground.steps.step-2.time-zone-list.table.customerStepPosition"), sortable: false},
        { key: "userClubStepBookingDuration", class: this.alignCellsToLeft, label: this.$t("views.playground.steps.step-2.time-zone-list.table.managementStepPosition"), sortable: false},
        { key: "paymentMethods",class: this.alignCellsToLeft, label: this.$t("views.playground.steps.step-2.time-zone-list.table.paymentModes"), sortable: false },
        { key: "actions", label: this.$t('general.actions.actions')},
      ];
    }
  },
  methods: {
    onRowDelete (block) {
      deleteBlock(block.id)
          .then((response) => {
            this.$bus.$emit('refresh-timetables');
            this.$flash(null,  this.$t('views.playground.steps.step-2.time-zone-list.success-delete'), 5000, SUCCESS);
          })
          .catch(() => {
            this.$flash(this.$t('general.actions.error'), this.$t('general.actions.error-notification'));
          })
      ;
    },
    onRowEdit (row) {
      this.block  = new Block(row, {deserialize: true});
      this.block.startAt.HH = this.$moment.utc(row.block.startAt).format('HH');
      this.block.startAt.mm = this.$moment.utc(row.block.startAt).format('mm');
      this.block.endAt.HH = this.$moment.utc(row.block.endAt).format('HH');
      this.block.endAt.mm = this.$moment.utc(row.block.endAt).format('mm');
      this.block.paymentMethods = [];
      this.model = row;
      this.display = !this.display;
    },
    onNewTimeTableBlock () {
      if (!this.isDisabled) {
        this.block = new Block();
        this.display = !this.display;
      }
    },
    onPaginationUpdate (page) {
      this.currentPage = page;
    }
  },
  beforeDestroy () {
    try {
      this.$bus.$off('on:timetable:check');
    } catch (e) {}
  },
  mounted () {
    this.$bus.$on('on:timetable:check', (timetable) => {
      this.componentModalKey ++;
      this.isBusy = true;
      this.selectedTimetable = timetable;
      this.isDisabled = false;
      this.items = [];

      if (timetable !== null && timetable.blocks !== null) {
        const blocks = timetable.blocks;

        for (const block of blocks) {
          let newBlock = new Block(block, { row: true });

          Object.assign(newBlock, { actions: ['edit', 'delete'], block: { startAt: block.startAt, endAt: block.endAt } });
          newBlock.startAt = block.startAt;
          this.items.push(newBlock);
        }
        this.items.sort((a,b) => (a.startAt > b.startAt) ? 1 : ((b.startAt > a.startAt) ? - 1 : 0))
      }

      this.totalRows = this.items.length;

      setTimeout(() => {
        this.isBusy = false
      }, 400);
    });
  }
}
</script>
<style scoped>
.playground-generals-inf-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 190px;
  padding: 1.25rem;
}
.playgraound-general-title {
  text-align: left;
  font: normal normal 900 22px Avenir;
  letter-spacing: 0px;
  color: #FF0101;
  opacity: 1;
}
.playground-generals-inf-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}
</style>
