import {_delete, _get, _post, _put} from "@api/doinsport/services/httpService";

export const URI_BLOCK = "/clubs/playgrounds/timetables/blocks";

export const postBlock = (block) => _post(URI_BLOCK, block);

export const putBlock = (block) => _put(URI_BLOCK + '/' + block.id, block);

export const getBlock = (id) => _get(URI_BLOCK + '/' + id);

export const getAllBlocks = (filters) => _get(URI_BLOCK + '?itemsPerPage=' + 500 + filters);

export const deleteBlock = (id) => _delete(URI_BLOCK + '/' + id );

export const getBlocks = (id, itemsPerPage, page) => _get(URI_BLOCK + '/' + id + '?itemsPerPage=' + itemsPerPage + '&page='+ page);
