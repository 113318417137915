import {hydrate} from "@/utils/form";
import Vue from "vue";

export default class Block {
  id = null;
  name = null;
  startAt = null;
  endAt = null;
  instalmentPercentage = null;
  userClientStepBookingDuration = 5;
  userClubStepBookingDuration = 5;
  paymentMethods = [];
  prices = [];
  timetable = null;
  maxParticipantsCountLimit = 50;
  timezone = 'Europe/Paris';

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.serialize) {
        this.serialize(object);
      }
      if (options.deserialize) {
        this.deserialize(object);
      }
      if (options.row) {
        hydrate(this, object);
        this.formatTimes();
        this.castToMinutes();
      }
      if (options.step3_serialize) {
        hydrate(this, object);
        this.step3TimesCustomFormat(object);
      }
      if (options.timezone) {
        this.timezone = options.timezone;
      }
    }
  }

  serialize(object) {
    hydrate(this, object);
    this.formatTimes();
    this.castToSeconds();
  }

  deserialize(object) {
    hydrate(this, object);
    this.formatDatesTimes(object);
  }

  formatDatesTimes(object) {
    this.startAt = {
      HH: Vue.prototype.$moment.utc(object.block.startAt).tz(this.timezone, true).format('HH'),
      mm: Vue.prototype.$moment.utc(object.block.startAt).tz(this.timezone, true).format('mm')
    };
    this.endAt = {
      HH: Vue.prototype.$moment.utc(object.block.endAt).tz(this.timezone, true).format('HH'),
      mm: Vue.prototype.$moment.utc(object.block.endAt).tz(this.timezone, true).format('mm')
    }
  }

  formatTimes() {
    this.startAt = this.startAt.HH + ':' + this.startAt.mm;
    this.endAt = this.endAt.HH + ':' + this.endAt.mm;
  }

  step3TimesCustomFormat(object) {
    const stHH = Vue.prototype.$moment.utc(object.startAt).tz(this.timezone, true).format('HH');
    const stMM = Vue.prototype.$moment.utc(object.startAt).tz(this.timezone, true).format('mm');
    this.startAt = stHH + ':' + stMM;

    const etHH = Vue.prototype.$moment.utc(object.endAt).tz(this.timezone, true).format('HH');
    const etMM = Vue.prototype.$moment.utc(object.endAt).tz(this.timezone, true).format('mm');
    this.endAt = etHH + ':' + etMM;
  }

  castToSeconds() {
    this.userClientStepBookingDuration = parseInt(this.userClientStepBookingDuration) * 60;
    this.userClubStepBookingDuration = parseInt(this.userClubStepBookingDuration) * 60;

  }

  castToMinutes() {
    this.userClientStepBookingDuration = parseInt(this.userClientStepBookingDuration) / 60;
    this.userClubStepBookingDuration = parseInt(this.userClubStepBookingDuration) / 60;
  }
}
